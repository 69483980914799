class Share {
  constructor(options){
    const properties = [ "og:title", "og:description", "og:image",  "og:url", "outbound:host",  "outbound:invite_token","outbound:resource_type", "outbound:resource_id"]
    properties.forEach(property => {
      let content = getMetaContent({property: property});
      this[property.split(":")[1]] = content;
    })
    if(this.url){
      this.slug = this.url.split("/").pop();
      if (!this.url.match(this.host)) {
        this.url = this.host + "/" + this.url;
      }
      this.url = this.url.replace(/(beta)|(staging)/, 'www');
      this.campaign = "sharing";
      this.customize();
    }
  }

  customize () {
    if (window['current_user'] && current_user.id !== undefined) {
      document.querySelectorAll(`[data-resource-id][data-user-id='${current_user.id}']:not([data-state='active'])`).forEach((el,i) =>{
        el.querySelector('.actions').prepend(this.delete(el));
        el.querySelector('.actions').prepend(this.edit(el));
      })
      const cList = document.querySelector('body').classList;

      const cProfile = (cList.contains('profile') && (cList.contains('saves') || cList.contains('done')))
      const cListPage =  (cList.contains('lists') && (cList.contains('show')))
      if (cProfile || cListPage) {
        document.querySelectorAll('li[data-resource-id]').forEach((el) => {
          if(!(el.querySelector('.actions .remove'))){
            el.querySelector('.actions').prepend(this.remove(el));
          }
        })
      }
    }
  }
  
  facebook(obj) {
    var s;
    this.format(obj, "facebook");
    s = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.url);
    return window.open(s, 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=585,height=300');
  }

  twitter(obj) {
    this.format(obj, "twitter");
    if (!!(this.title.match('The Outbound'))) {
      this.title = this.title.replace('The Outbound', '@theoutbound');
    } else if (!(this.title.match('@theoutbound'))) {
      this.title = this.title = this.title + " @theoutbound";
    }
    return window.open("http://twitter.com/share?url=" + encodeURIComponent(this.url) + "&text=" + encodeURIComponent(this.title) + "&related-account=theoutbound&count=none/", "tweet", "height=300,width=500,resizable=1");
  }

  pinterest(obj) {
    var s;
    this.format(obj, "pintrerest");
    s = "https://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(this.url) + "&description=" + encodeURIComponent(this.title) + "&media=" + encodeURIComponent(this.image);
    return window.open(s);
  }

  email(obj) {
    this.format(obj, "email");
    return window.location = "mailto:?subject=" + encodeURIComponent("Check this out! -" + this.title) + "&body=" + encodeURIComponent("Found this awesome story on The Outbound.\n\n" + this.title + "\n" + this.description + "\n\n" + this.url);
  }

  format(obj, medium) {
    var li;
    if (obj == null) {
      obj = false;
    }
    if (medium == null) {
      medium = "theoutbound.com";
    }
    if (obj) {
      window.obj = obj;
      li = document.querySelector(obj).closest('[data-resource-id]');
      this.title = document.querySelector(li).dataset['name'];
      this.description = document.querySelector(li).dataset['summary'] || li.querySelector('.description').innerHTML;
      this.image = "http:" + (li.dataset['adventureImage'] || li.dataset['adventureCoverUrl']);
      this.url = li.dataset['url'];
    }
    if (!!(this.title.match('@theoutbound'))) {
      this.title = this.title.replace('@theoutbound', 'The Outbound');
    }
    this.url = this.format_url(medium);
    return li;
  }

  format_url(medium) {
    if (medium == null) {
      medium = "theoutbound.com";
    }
    if (!(this.url.match(this.host))) {
      this.url = "http://" + this.host + this.url;
    }
    if (!!(this.url.match(/\/(contacts)|(purchases)|(settings)|(activities)$/))) {
      this.url = "http://" + this.host;
    }
    if (!(this.url.match(/\/i\/|\?i=/)) && this.invite_token && this.invite_token.length > 0) {
      this.url = (this.url.replace(/\/$/, '')) + "?ck=" + this.invite_token;
    }
    this.url += !!(this.url.match(/\?/)) ? "&" : "?";
    this.url += "utm_source=theoutbound.com&utm_medium=" + medium + "&utm_campaign=" + this.campaign + "&cc=" + this.slug;
    return this.url;
  }

  delete(li) {
    if (current_user.id !== undefined && ((Number(li.dataset['userId']) === current_user.id && li.dataset['state'] !== 'active') || current_user.state=='super')) {
      const a = $HTML(`<a class="m1r font24 delete inline-block" title="Delete this Draft?" data-confirm="Deleting this draft is irreversible. Are you sure you want to proceed?" /><i class="fa fa-trash color-white text-shadow icon-large"/></a>`)
      a.onclick = (e) => {
        e.preventDefault();
        return $Fetch('DELETE', li.dataset['url'],{}, (data)=>{
          li.remove();
          App.Flash.new('success', JSON.parse(data).message);
        }, (e)=> {
          App.Flash.new('error', e)
        })
      }
      return a;
    } else {
      return ''
    }
  }

  edit(li) {
    if (current_user.id !== undefined && ((Number(li.dataset['userId']) === current_user.id && li.dataset['state'] !== 'active') || current_user.state == 'super')) {
      return $HTML(`<a class="m1r font24 edit inline-block" href="${li.dataset['url']}/edit" title="Edit Draft?"/><i class="fa fa-edit color-white text-shadow icon-large" />`)
    } else {
      return ''
    }
  }

  remove(li) {
    if (current_user.id !== undefined && ((Number(document.body.dataset['listUserId']) === current_user.id) || current_user.state == 'super')) {  
      const a = $HTML(`<a class="m1r font24 remove inline-block" title="Delete this Bookmark?" data-confirm='Deleting this Bookmark is irreversible. Are you sure you want to proceed?'/><i class="fa fa-minus-circle icon-large" /></a>`);
      a.onclick = (e) => {
        e.preventDefault();
        return $Fetch('DELETE', "/api/" + li.dataset['resourceType'] + "/" + li.dataset['resourceId'] + "/bookmarks",{
          group_id: document.body.dataset['groupId'],
          resource_type: li.dataset['resourceType'],
          resource_id: li.dataset['resourceId']
        }, (data)=>{
          li.remove();
        })
      }
      return a;
    } else {
      return ''
    }
  };
}
App.ready(()=> {
  window.share = new Share();
})
window.Share = Share;
App.Share = Share;