function load_photo_modal(ajax){
  function checkKey(e) {

    e = e || window.event;
    if (e.keyCode == '37') {
      document.querySelector('.photo-modal-previous').click();
    }
    else if (e.keyCode == '39') {
       document.querySelector('.photo-modal-next').click();
    }
  }

  document.onkeydown = checkKey;
  if(ajax != undefined){

    const photo_id =  document.querySelector('.photo-show').dataset['assetId'];
    const photo_path = document.querySelector('.photo-show').dataset['assetPath'];
    if(document.location.hash != "photo-"+photo_id){
      document.location.hash = "photo-"+photo_id;
    }

    if(current_user.id !== undefined){
      current_user.show_user_methods();
      current_user.update_liked_photos();
    }
    Unveil();
  }
}

window.load_photo_modal = load_photo_modal;